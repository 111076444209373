import React, { useState, useEffect } from 'react';
import './Gallery.css';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState('All');
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    // Fetch the list of images from a JSON file or an API endpoint
    fetch(`${process.env.PUBLIC_URL}/images/imageList.json`)
      .then(response => response.json())
      .then(data => {
        setImages(data);
        setOwners(getUniqueOwners(data));
      })
      .catch(error => console.error('Error fetching image list:', error));
  }, []);

  // Function to get unique owners
  const getUniqueOwners = (images) => {
    const ownerSet = new Set();
    images.forEach(image => {
      if (image.owner) {
        ownerSet.add(image.owner);
      }
    });
    return ['All', ...Array.from(ownerSet)];
  };

  // Filter images based on selected owner
  const filteredImages = images.filter(image => {
    return selectedOwner === 'All' || image.owner === selectedOwner;
  });

  return (
    <div className="gallery-container">
      <h2>Image Gallery</h2>

      <div className="owner-filter">
        <label htmlFor="owner-select">Filter by Owner:</label>
        <select
          id="owner-select"
          value={selectedOwner}
          onChange={(e) => setSelectedOwner(e.target.value)}
        >
          {owners.map((owner, index) => (
            <option key={index} value={owner}>
              {owner}
            </option>
          ))}
        </select>
      </div>

      <div className="gallery-grid">
        {filteredImages.map((image, index) => (
          <div
            className="gallery-item"
            key={index}
            onClick={() => setSelectedImage(image)}
            tabIndex="0" // Makes the div focusable
            onKeyDown={(e) => {
              if (e.key === 'Enter') setSelectedImage(image);
            }}
            aria-label={`Open image ${index + 1}`}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/${image.src}`}
              alt={image.title || `Gallery image ${index + 1}`}
            />
            <div className="gallery-item-caption">
              {image.title ? (
                <strong>{image.title}</strong>
              ) : (
                <em>{image.owner}</em>
              )}
            </div>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="lightbox"
          onClick={() => setSelectedImage(null)}
          role="dialog"
          aria-modal="true"
          aria-labelledby="lightbox-title"
        >
          <span
            className="close"
            onClick={() => setSelectedImage(null)}
            role="button"
            aria-label="Close"
            tabIndex="0"
            onKeyDown={(e) => {
              if (e.key === 'Enter') setSelectedImage(null);
            }}
          >
            &times;
          </span>
          <img
            className="lightbox-content"
            src={`${process.env.PUBLIC_URL}/images/${selectedImage.src}`}
            alt={selectedImage.title || 'Selected image'}
          />
          <div className="lightbox-caption">
            {selectedImage.owner && <h2>By {selectedImage.owner}</h2>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
