import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import PatchNotes from './components/PatchNotes';
import Contests from './components/Contests';
import InstallInstructions from './components/InstallInstructions';
import Recommended from './components/Recommended';
import Rules from './components/Rules';
import FAQ from './components/FAQ';
import HistoryOfWinners from './components/HistoryOfWinners';
import ConfigFiles from './components/ConfigFiles';
import ModList from './components/ModList';
import './App.css';
import { FaSyncAlt } from 'react-icons/fa';
import Gallery from './components/Gallery';
function Home() {
  const [serverStatus, setServerStatus] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const effects = ['spinScreen', 'spinElements', 'resizeElements'];

  const triggerRandomEffect = () => {
    const allElements = document.querySelectorAll('*');
    allElements.forEach(element => {
      // Remove any existing effects
      element.classList.remove('spinScreen', 'spinElements', 'resizeElements', 'spinReverse');
  
      // Randomly decide whether to apply an effect
      if (Math.random() < 0.5) { // Adjust the probability as desired
        const randomEffect = effects[Math.floor(Math.random() * effects.length)];
        element.classList.add(randomEffect);
  
        // 50% chance to spin in the opposite direction if the effect is a spin
        if (Math.random() < 0.5 && (randomEffect === 'spinScreen' || 'spinElements')) {
          element.classList.add('spinReverse');
        }
      }
    });
  };
  const fetchServerStatus = () => {
    setIsFetching(true);
    fetch('https://api.mcsrvstat.us/2/104.128.51.221:25577')
      .then(response => response.json())
      .then(data => {
        setServerStatus(data);
        setIsFetching(false);
      })
      .catch(error => {
        console.error('Error fetching server status:', error);
        setIsFetching(false);
      });
  };

  useEffect(() => {
    fetchServerStatus();
  }, []);

  return (
    <div className="home">
      <header className="App-header">
        <h1> Welcome to Origin Minecraft! </h1>
        <p>Your go-to site for all things related to Origin Minecraft.</p>
      </header>
      <div className="server-status">
        {isFetching ? (
          <>
            <p className="status-fetching">Status: Fetching...</p>
            <p className="status-fetching">Players Online: Fetching...</p>
          </>
        ) : serverStatus ? (
          <>
            <p className={serverStatus.online ? 'status-online' : 'status-offline'}>
              Status: {serverStatus.online ? 'Online' : 'Offline'}
            </p>
            <p>Players Online: {serverStatus.players ? `${serverStatus.players.online}/${serverStatus.players.max}` : 'N/A'}</p>
          </>
        ) : (
          <p>Loading server status...</p>
        )}
        <button className="refresh-button" onClick={fetchServerStatus} disabled={isFetching}>
          <FaSyncAlt />
        </button>
      </div>
      <button className="trigger-funny-effect-button" onClick={triggerRandomEffect}>Trigger Funny Effect</button>
      <div className="home-content">
        <section onClick={() => navigate("/patchnotes")}>
          <h2>Latest Patch Notes</h2>
          <p>Stay updated with the latest changes and improvements ( or deterioration ) in the game.</p>
        </section>
        <section onClick={() => navigate("/contests")}>
          <h2>Current Contests</h2>
          <p>Participate in the building contests and showcase your skills.</p>
        </section>
        <section onClick={() => navigate("/install-instructions")}>
          <h2>Install Instructions</h2>
          <p>Get started with Origin Minecraft by following our installation guide.</p>
        </section>
        <section onClick={() => navigate("/rules")}>
          <h2>Rules</h2>
          <p>Understand the rules and guidelines to ensure a fair play environment.</p>
        </section>
        <section onClick={() => navigate("/faq")}>
          <h2>FAQ</h2>
          <p>Find answers to common questions about the server.</p>
        </section>
        <section onClick={() => navigate("/history-of-winners")}>
          <h2>History of Winners</h2>
          <p>Check out the winners of previous contests and their amazing builds.</p>
        </section>
        <section onClick={() => navigate("/modlist")}>
          <h2>Mod List</h2>
          <p>View the list of mods used on the server.</p>
        </section>
        <section onClick={() => navigate("/recommended")}>
          <h2>Recommended Client Mods</h2>
          <p>View the list of community recommended mods!</p>
        </section>
        <section onClick={() => navigate("/config-files")}>
          <h2>Configuration Files</h2>
          <p>View and download the server configuration files.</p>
        </section>
        <section onClick={() => navigate("/gallery")}>
          <h2>Gallery</h2>
          <p>Some screenshots taken from judging</p>
        </section>
      </div>
    </div>
  );
}
function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <main className="App-main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/patchnotes" element={<PatchNotes />} />
            <Route path="/contests" element={<Contests />} />
            <Route path="/install-instructions" element={<InstallInstructions />} />
            <Route path="/rules" element={<Rules />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/history-of-winners" element={<HistoryOfWinners />} />
            <Route path="/modlist" element={<ModList />} />
            <Route path="/recommended" element={<Recommended />} />
            <Route path="/config-files" element={<ConfigFiles />} />
            <Route path="/gallery" element={<Gallery />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}
export default App;
